
























































































import { api } from "@/api/api";
import { ApiGetCoursesAndNewDocumentsDto } from "@/api/generated/Api";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { DashboardUser } from "@/shared/constants/dashboardUser";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { DashboardRouteNames } from "@/shared/enums/RouteNames/dashboardRouteNames.enum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { formatLocalizedDateP } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { capitalize } from "@/shared/helpers/textHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

// Inherits course information from ApiGetCoursesAndNewDocumentsDto
interface NewDocumentItem extends Omit<ApiGetCoursesAndNewDocumentsDto, "newDocumentParticipants"> {
  // User
  userId: number;
  courseParticipantName?: string | null;
  courseParticipantStatus?: string | null;

  // Document
  documentName?: string | null;
  fileId: number;
  documentCategory?: string | null;
  inserted: string;
  isCourseAdminRead: boolean;
}

export default defineComponent({
  components: { BaseLayout, BaseTableFiltered },
  name: "NewDocumentsPage",
  setup() {
    const store = useStore<StoreState>();
    const localStorageName = "newDocumentsPageGroupBy";
    const selectedRows = ref<NewDocumentItem[]>([]);
    const flattenedDocuments = ref<NewDocumentItem[]>([]);
    const isFilterOpen = ref(false);
    const currentGroupBy = ref<string | null>(localStorage.getItem(localStorageName) || null);

    const getCourseLink = (courseId: string, participantName: string) => ({
      name: SingleCourseRouteNames.CourseFiles,
      params: {
        id: courseId,
        searchQuery: participantName,
      },
    });

    const markAsRead = async (selectedDocuments: any[]) => {
      const documents = selectedDocuments.map(({ courseId, userId, fileId }) => ({
        courseId,
        userId,
        fileId,
      }));

      globalLoadingWrapper({ blocking: true }, async () => {
        await api.dashboard.markNewDocumentsAsRead(documents).then;
        openNotification(store, NotificationItemType.Success, "Dokumenter ble merket som sett");
        selectedRows.value = [];
        await getNewDocuments();
      });
    };

    const updateGroupBy = (value: string | null) => {
      if (value) {
        currentGroupBy.value = value;
        localStorage.setItem(localStorageName, value);
      } else {
        currentGroupBy.value = null;
        localStorage.removeItem(localStorageName);
      }
    };

    const selectAllToggle = ({ items, value }: { items: NewDocumentItem[]; value: boolean }) => {
      if (value) {
        selectedRows.value = items.filter((item) => !item.isCourseAdminRead);
      } else {
        selectedRows.value = [];
      }
    };

    const getNewDocuments = () => {
      const userId = Number(window.sessionStorage.getItem(DashboardUser.USER_ID));
      globalLoadingWrapper({ blocking: true }, async () => {
        const fetchedDocuments = (await api.dashboard.getCoursesWithNewDocuments({ userId })).data;

        flattenedDocuments.value = flattenDocuments(fetchedDocuments);
      });
    };

    onMounted(() => {
      getNewDocuments();
    });

    return {
      dashboardLink: () => useNavigateBack(DashboardRouteNames.Home),
      selectedRows,
      flattenedDocuments,
      isFilterOpen,
      markAsRead,
      getCourseLink,
      formatLocalizedDateP,
      headers,
      currentGroupBy,
      selectAllToggle,
      updateGroupBy,
      capitalize,
    };
  },
});

const headers = [
  {
    text: "ID",
    value: "courseId",
  },
  {
    text: "Tittel",
    value: "courseName",
  },
  {
    text: "Studiested fagskole",
    value: "courseLocation",
  },
  {
    text: "Ansvarlig",
    value: "courseSupervisorName",
  },
  {
    text: "Administrator",
    value: "courseLecturerName",
  },
  {
    text: "Navn",
    value: "courseParticipantName",
  },
  {
    text: "Påmeldingsstatus",
    value: "courseParticipantStatus",
  },
  {
    text: "Dokumentkategori",
    value: "documentCategory",
  },
  {
    text: "Dokumentnavn",
    value: "documentName",
  },
  {
    text: "Opplastningsdato",
    value: "inserted",
  },
  {
    text: "Sett",
    value: "isCourseAdminRead",
  },
  {
    text: "Handlinger",
    value: "actions",
    sortable: false,
  },
];

const flattenDocuments = (documents: ApiGetCoursesAndNewDocumentsDto[]): NewDocumentItem[] =>
  documents.flatMap((course) =>
    (course.newDocumentParticipants ?? []).flatMap((participant) =>
      (participant.newDocuments ?? []).map((file) => ({
        ...course,
        ...participant,
        ...file,
      }))
    )
  );
