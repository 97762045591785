var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseLayout',{staticClass:"px-0",attrs:{"displayTabs":_vm.isFilterOpen},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":""},on:{"click":_vm.dashboardLink}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_vm._v(" Nye dokumenter ")]},proxy:true},{key:"actions",fn:function(){return [(_vm.selectedRows.length > 0)?_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","data-cy":"markAsReadBtn"},on:{"click":function($event){return _vm.markAsRead(_vm.selectedRows)}}},[_vm._v(" Marker som sett ")]):_vm._e(),_c('v-btn',{attrs:{"color":"white","outlined":""},on:{"click":function($event){_vm.isFilterOpen = !_vm.isFilterOpen}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-variant")]),_vm._v(" Filter ")],1)]},proxy:true},{key:"tabs",fn:function(){return [(!_vm.currentGroupBy)?_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":""},on:{"click":function($event){return _vm.updateGroupBy('courseId')}}},[_vm._v(" Grupper etter ID ")]):_vm._e(),(!_vm.currentGroupBy)?_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.updateGroupBy('courseParticipantName')}}},[_vm._v(" Grupper etter navn ")]):_vm._e(),(_vm.currentGroupBy)?_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.updateGroupBy(null)}}},[_vm._v(" Fjern gruppering ")]):_vm._e()]},proxy:true}])},[[_c('BaseTableFiltered',{attrs:{"headers":_vm.headers,"items":_vm.flattenedDocuments,"group-by":_vm.currentGroupBy,"sort-by":['courseId', 'courseParticipantName'],"item-key":"fileId","show-select":""},on:{"toggle-select-all":_vm.selectAllToggle,"update:group-by":_vm.updateGroupBy},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"ripple":false,"value":isSelected,"readonly":item.isCourseAdminRead,"disabled":item.isCourseAdminRead},on:{"input":function($event){return select($event)}}})]}},{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
var remove = ref.remove;
var groupBy = ref.groupBy;
return [_c('td',{staticClass:"group-header",attrs:{"colspan":"12"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","small":""},on:{"click":toggle}},[_c('v-icon',[_vm._v(_vm._s(isOpen ? "mdi-minus" : "mdi-plus"))])],1),_vm._v(" "+_vm._s(groupBy.includes("courseParticipantName") ? items[0].courseParticipantName : "")+" "+_vm._s(groupBy.includes("courseId") ? items[0].courseId : "")+" "),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":remove}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1)]}},{key:"item.courseParticipantStatus",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.capitalize(value)))]}},{key:"item.inserted",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatLocalizedDateP(value))+" ")]}},{key:"item.isCourseAdminRead",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? "Ja" : "Nei")+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","to":_vm.getCourseLink(item.courseId, item.courseParticipantName)}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("Vis kurset")])])]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }